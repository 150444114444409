import React from "react";
import { importAll } from "./Helper";
import { Link } from "react-router-dom";
import "../css/Category.css";

export default function Category(props) {
  const dUid = props.dUid;

  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <>
      <div className="agentinfobox-container ">
        <div className="category-heading">
          <p className="category-title">Projects</p>
        </div>

        <div className="grid">
          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Savings Account&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["bank.svg"]} alt="" className="categoryImage" />
              <p className="categoryName">Savings Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Demat Account&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img
                src={images["demat_new.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">Demat Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Current Account&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["current_acc_logo.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Current Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Credit Card&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img
                src={images["cc_new.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">Credit Card</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=BNPL&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img
                src={images["bnpl_new.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">BNPL</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects/Loans",
              search: `?category=Loan&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img
                src={images["loan_new.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">Loan</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Investment Account&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["investment_new.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Investment Account</p>
            </div>
          </Link>
          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=App Download&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["app_download.png"]} alt="" className="categoryImage" />
              <p className="categoryName">App Download</p>
            </div>
          </Link>
          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=UPI&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["UPI2a.png"]} alt="" className="categoryImage" />
              <p className="categoryName">UPI</p>
            </div>
          </Link>
          <Link
            className="linkClass"
            to={{
              pathname: "/Projects",
              search: `?category=Business Account&dist=${dUid}`,
            }}
          >
            <div className="grid-item">
              <img src={images["business-account.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Business Account</p>
            </div>
          </Link>
        </div>
      </div>{" "}
    </>
  );
}
